import HomeIcon from '@mui/icons-material/HomeOutlined';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import ArticleIcon from '@mui/icons-material/Article';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PaidIcon from '@mui/icons-material/Paid';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import EventNoteIcon from '@mui/icons-material/EventNote';

const NavLinks = {
    dashboard: [
        {
            name: 'Dashboard',
            icon: <HomeIcon fontSize='medium' />,
            to: '/',
        },

        {
            name: 'Attendance',
            icon: <CoPresentIcon fontSize='small' />,
            to: '/attendance',
        },

        {
            name: 'My Leaves',
            icon: <TimeToLeaveIcon fontSize='small' />,
            to: '/my-leaves',
        },
        {
            name: 'Notice Board',
            icon: <EventNoteIcon fontSize='small' />,
            to: '/notice',
        },
        {
            name: 'Projects',
            icon: <LibraryBooksIcon fontSize='small' />,
            to: '/projects',
        },
        {
            name: 'Payslips',
            icon: <ReceiptIcon fontSize='small' />,
            to: '/payslips',
        },
        {
            name: 'Expenses',
            icon: <PaidIcon fontSize='small' />,
            to: '/expenses',
        },
        {
            name: 'On Boarding',
            icon: <ArticleIcon fontSize='small' />,
            to: '/on-boarding',
        },
    ],
};

export { NavLinks };
