import React, { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../components/Loading';
import axios from 'axios';
import { env } from '../utils/function';
import { useLocation } from 'react-router-dom';

const authenticateContext = createContext();

const AuthenticationProvider = ({ children }) => {
    const [content, setContent] = useState(<Loading message='Please wait, logging you in...' />);
    const [user, setUser] = useState({});
    const location = useLocation();
    // const [employees, setEmployees] = useState({});
    // const [employeeInfo, setEmployeeInfo] = useState([]);
    // const getDomain = url => url.replace(/(http:\/\/|https:\/\/)/, '');

    const authenticate = async (loggedIn, cb) => {
        if (loggedIn) {
            setContent(children);
        } else {
            const redirectTo =
                env('AUTHENTICATION_CLIENT') +
                '/login?redirectto=' +
                encodeURIComponent(env('DOMAIN') + location.pathname + location.search);

            setContent(
                <Loading
                    message={`Please wait, redirecting you to ${process.env.REACT_APP_COMPANY} Accounts`}
                    redirectTo={redirectTo}
                />
            );
        }
        if (typeof cb === 'function') cb(setUser);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await axios.get(`/profile`);
                const user = response.data.user;
                console.log(user);

                authenticate(true, setUser => setUser(user));
            } catch (err) {
                console.log(err);
                authenticate(false);
            }
        })();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <authenticateContext.Provider value={{ authenticate, setUser, user }}>
            {content}
        </authenticateContext.Provider>
    );
};

const useAuthenticate = () => useContext(authenticateContext).authenticate;
const useUser = () => useContext(authenticateContext).user;
const useSetUser = () => useContext(authenticateContext).setUser;

export default AuthenticationProvider;
export { useAuthenticate, useUser, useSetUser };
