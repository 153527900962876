import { Box, Button, Card, CardContent, IconButton, Typography } from '@mui/material';
import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import { Link } from 'react-router-dom';
import { calcLeaveDays } from '../utils/function';

const LeaveOverview = ({ leaves }) => {
    return (
        <>
            <Card sx={{ height: '100%', position: 'relative' }} elevation={0}>
                <CardContent>
                    <Box
                        sx={{
                            borderLeftWidth: '5px',
                            borderLeftColor: 'primary.main',
                            borderLeftStyle: 'solid',
                            height: '40px',
                            width: '5px',
                            position: 'absolute',

                            left: 1,
                        }}></Box>
                    <Box mb={5} display='flex' alignItems='center' justifyContent='space-between'>
                        <Typography variant='h6'>Leave Balance</Typography>

                        <Button variant='contained' LinkComponent={Link} to='/my-leaves'>
                            Apply For Leaves
                        </Button>
                    </Box>
                    {/* table */}
                    <TableContainer
                        sx={{
                            '::-webkit-scrollbar': { display: 'none' },
                        }}>
                        <Table aria-label='simple table' padding='checkbox' size='small'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Leave type</TableCell>
                                    <TableCell align='center'>Days</TableCell>
                                    <TableCell align='center'>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leaves
                                    ? leaves.map(row => (
                                          <TableRow
                                              key={row.leave}
                                              sx={{
                                                  '&:last-child td, &:last-child th': { border: 0 },
                                              }}>
                                              <TableCell
                                                  component='th'
                                                  scope='row'
                                                  padding='checkbox'>
                                                  <Box display='flex' alignItems='center' my={1}>
                                                      <IconButton>
                                                          <CircleIcon
                                                              fontSize='small'
                                                              sx={{
                                                                  color: '#3366FF',
                                                              }}
                                                          />
                                                      </IconButton>

                                                      <Typography variant='caption'>
                                                          {row.leave}
                                                      </Typography>
                                                  </Box>
                                              </TableCell>
                                              <TableCell align='center'>
                                                  {row.dates.length === 1
                                                      ? '1'
                                                      : calcLeaveDays(row.dates[0], row.dates[1])}
                                              </TableCell>
                                              <TableCell align='center'>{row.status}</TableCell>
                                          </TableRow>
                                      ))
                                    : null}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* footer */}
                </CardContent>
            </Card>
        </>
    );
};

export default LeaveOverview;
