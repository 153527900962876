import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Images } from '../../components/Image';

const InitialStep = () => {
    return (
        <>
            <Grid
                container
                spacing={0}
                display='flex'
                alignItems='center'
                style={{ height: '45vh' }}
            >
                <Grid
                    item
                    lg={4}
                    xs={12}
                    sx={{ textAlign: { lg: 'right', xs: 'center' } }}
                    justifyContent='center'
                >
                    <Images src='interview.svg' style={{ height: '200px' }} />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <Typography sx={{ maxWidth: '700px', ml: 4 }} variant='h6'>
                        "Patience is the key to success. Your journey begins
                        with the initial step of the interview. Embrace this
                        moment and prepare to shine. Your time is coming, so
                        stay tuned!"
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default InitialStep;
